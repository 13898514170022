import React from "react"
import Layout from "../components/layout"
import HomeBanner from "../components/homeBanner"
import Mapa from '../components/mapa'
import SEO from "../components/seo"
import {
    Container
} from 'react-bootstrap'

const curso = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <HomeBanner />
    <div id="nuestros-cursos">
        <Container>
            <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>Técnicas de investigación de causas de incidentes y accidentes laborales (evita)</h1>
            <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "9%", marginBottom: "1%" }}>Descripción: </h1>
            <div id="nuestros-cursos-text" className="text-justify">
                <p>
                El curso de técnicas de investigación de causas de incidentes y accidentes laborales (evita) va dirigido alpersonal del área de seguridad minera, está orientado a mejorar la seguridad laboral reaccionar ante los eventos de alto potencial, y accidentes graves y/o fatales para todas las divisiones y actividades.
    Este curso tiene como finalidad el aplicar técnicas de investigación de causas de incidentes y accidentes laborales examinando las causas y los factores contribuyentes que conducen a los eventos a fin de que las acciones correctivas y preventivas puedan ser implementadas para prevenir la recurrencia de un incidente o accidentes.
                </p>
            </div>
            <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "3%", marginBottom: "1%" }}>Contenidos: </h1>
            <div id="nuestros-cursos-text" className="text-justify">
                <p>
                    Aplicar técnicas para la investigación de incidentes y accidentes laborales de acuerdo con procedimientos corporativos y normativa vigente
                </p>
                <p className="puntos-cursos">
                    <ul>
                        <li>Módulo 1: Fundamentos De La Investigación De Accidentes/Incidentes Laborales: Definiciones, Contexto Y Propósito, Proceso De Investigación De Incidentes. Ley 16.744. Decretos Supremos: 40; 54; 76; 594, 18 Y Ds 109 Y Su Modificación El Ds 73.</li>
                        <li>Módulo 2: Proceso Investigativo De Accidentes E Incidentes Laborales El Error Humano. Porqué Ocurren Lo Incidentes. Gestión De Riesgos. Acciones Inmediatas Planificación Estrategica De La Investigación Recopilación Y Organización De Los Datos.</li>
                        <li>Módulo 3: Medidas Correctivas Y Preventivas Basadas En Reportes De Hallazgos: Comisión Investigadora. Reporte De Hallazgo. Análisis Causal. Acciones Preventivas Y Correctivas .Carta Gantt De Actividades.</li>
                    </ul>
                </p>
            </div>
            <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "3%", marginBottom: "1%" }}>Duración: </h1>
            <div id="nuestros-cursos-text" className="text-justify" style={{ color: "#FF6751" }}>
                <p>
                    <ul>
                        <li>8 Horas</li>
                    </ul>
                </p>
            </div>
        </Container>
    </div>
    <Mapa />
  </Layout>
)

export default curso
