/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FaArrowUp } from 'react-icons/fa'
import { Link } from 'react-scroll'

import Header from "./header"
import Footer from "./footer"
import "./andessite.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div id="main">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          <Footer />
          <Link id="link-top" to="inicio" spy={true} smooth={true} duration={1200} style={{ border: "solid 1px #fff", width: "36px", height: "36px", borderRadius: "20px", paddingTop: "4px",textAlign: "center", color: "#fff", backgroundColor: "#FF6751", position: "fixed", zIndex: "999", bottom: "20px", right: "20px" }}><FaArrowUp /></Link>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
