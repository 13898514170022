import React, { Component } from 'react'
import { FaPhone, FaEnvelope, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import {
    Nav,
    Navbar,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import logoMain from '../images/logo-main.png'
import { Link } from 'react-scroll'
import { Link as Link2} from 'gatsby'

export default class header extends Component {
    render() {
        return (
            <header id="inicio">
                <Navbar id="social-andes" expand="lg"  className="justify-content-end">
                    <Nav as="ul">
                        <Nav.Item as="li"><span className="nav-link"> <FaEnvelope style={{ marginBottom: "4px", fontSize: "20px", marginRight: "5px" }} /> contacto@andescci.com</span></Nav.Item>
                        <Nav.Item as="li"><span className="nav-link"> <FaPhone style={{ marginBottom: "4px", transform: "rotate(100deg)" }} />52-2253699</span></Nav.Item>
                        <Nav.Item as="li"><span className="nav-link"> <a href="https://www.facebook.com/andescci/"><FaFacebookF style={{ fontSize: "20px", marginBottom: "5px", color: "white" }} /></a></span></Nav.Item>
                        <Nav.Item as="li"><span className="nav-link"> <FaLinkedinIn style={{ fontSize: "25px", marginBottom: "4px" }} /></span></Nav.Item>
                    </Nav>
                </Navbar>
                <Navbar id="menu-andes" expand="lg">
                    <Link2 className="navbar-brand" to="/"><img id="logo-andes" src={logoMain} alt={this.props.siteTitle} /></Link2>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Item><Link to="inicio" spy={true} smooth={true} duration={1200} className="nav-link" style={{ cursor: "pointer" }}>Inicio</Link></Nav.Item>
                            <Nav.Item><Link to="nosotros-andes" spy={true} smooth={true} duration={1200} className="nav-link" style={{ cursor: "pointer" }} >Nosotros</Link></Nav.Item>
                            <Nav.Item><Link to="nuestros-cursos" spy={true} smooth={true} duration={1200} className="nav-link" style={{ cursor: "pointer" }} >Cursos y Certificaciones</Link></Nav.Item>
                            <Nav.Item><Link to="nuestros-clientes" spy={true} smooth={true} duration={1200} className="nav-link" style={{ cursor: "pointer" }} >Clientes</Link></Nav.Item>
                            <Nav.Item><Link to="footer" spy={true} smooth={true} duration={1200} className="nav-link" style={{ cursor: "pointer" }} >Contacto</Link></Nav.Item>
                            <Nav.Item><a href="https://cursos.andescci.com" className="nav-link" >Certificados</a></Nav.Item>
                            <Nav.Item><a href="https://andescci.com/revista/index.html" className="nav-link">Revista Andes</a></Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        )
    }
}

header.propTypes = {
    siteTitle: PropTypes.string,
}

header.defaultProps = {
    siteTitle: ``,
}